import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import ForgotPasswordPage from './pages/ForgotPasswordPage';
import DashboardPage from "./pages/DashboardPage";
import ItemsPage from "./pages/ItemsPage";
import { useAuth } from './context/AuthProvider';
import App from "./App";

const AppRouter = () => {
    const { isAuthenticated } = useAuth(); // Access the authentication status

    return (
        <Router>
            <Routes>
                {!isAuthenticated ? (
                    <>
                        <Route path="/login" element={<LoginPage />} />
                        <Route path="/register" element={<RegisterPage />} />
                        <Route path="/forgot-password" element={<ForgotPasswordPage />} />
                        <Route path="*" element={<Navigate to="/login" />} /> {/* Redirect to login */}
                    </>
                ) : (
                    <>
                        {/* Main App Layout */}
                        <Route path="/" element={<App />}>
                            <Route index element={<DashboardPage />} /> {/* Default route to dashboard */}
                            <Route path="items" element={<ItemsPage />} /> {/* Nested route for items */}
                        </Route>
                        <Route path="*" element={<Navigate to="/" />} /> {/* Redirect to dashboard */}
                    </>
                )}
            </Routes>
        </Router>
    );
};

export default AppRouter;
