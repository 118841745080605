const en = {
    MainApp: {
        Navigation: {
            home: 'Home',
            items: 'Items',
            card: 'Card',
            campaigns: 'Campaigns',
            profile: 'Profile',
        },
        BottomNav: {
            home: "Home",
            items: "Items",
            card: "Card",
            campaigns: "Campaigns",
            profile: "Profile",
            qrTitle: "QR Code & Barcode",
            qrTab: "QR Code",
            barcodeTab: "Barcode",
            close: "Close"
        },
        Menu: {
            warranty: 'Digital Warranties Management',
            items: 'Items Management',
            campaigns: 'Active Campaigns',
            organizations: 'Organizations',
            points: 'Points',
            settings: 'Settings',
            appSettings: 'App Settings',
            notifications: 'Notifications',
            inviteFriends: 'Invite Friends',
            contact: 'Contact',
            logout: 'Logout',
        },
        Dashboard: {
            warranty: 'Manage your digital warranties here.',
            items: 'Keep track of your items.',
            campaigns: 'See active campaigns.',
            organizations: 'Manage organizations.',
            settings: 'Adjust your app settings.',
        },
        Items: {
            title: "Manage Your Items",
            description: "Here you can find details about your purchased items.",
            selectManufacturer: "Select Manufacturer",
            selectSeller: "Select Seller",
            priceUnavailable: "Price unavailable",
            purchaseDate: "Purchase Date",
            noItems: "No items found.",
            underWarranty: "Under Warranty",
            warrantyNearExpiry: "Warranty Expiring Soon",
            warrantyExpired: "Warranty Expired",
        },
        "Promotions": {
            "loading": "Loading promotions...",
            "name": "Promotion Name",
            "description": "Description",
            "organization": "Organization"
        },
        "pointsPreview": {
            "allPoints": "All Points",
            "organizationPoints": "Points"
        },
        "warrantyPreview": {
            "expiry": "Warranty Expiry",
            "allUnderWarranty": "All items are currently under warranty"
        },
    },
    splash: {
        login: 'Login',
        register: 'Register',
        checkingAuth: 'Checking authentication...',
    },
    login: {
        title: "Login",
        emailPlaceholder: "Email",
        passwordPlaceholder: "Password",
        loginButton: "Login",
        registerPrompt: "Don't have an account? Register here.",
        forgotPassword: "Forgot Password?",
    },
    register: {
        title: "Register",
        emailPlaceholder: "Enter your email",
        passwordPlaceholder: "Enter your password",
        confirmPasswordPlaceholder: "Confirm your password",
        registerButton: "Register",
        loginRedirect: "Already have an account? Log in",
        errors: {
            passwordMismatch: "Passwords do not match.",
        },
        success: "Registration successful with email: {{email}}",
    },
    "forgotPassword": {
        "title": "Forgot Password",
        "emailPlaceholder": "Enter your email",
        "sendButton": "Send",
        "backButton": "Back",
        "emailRequired": "Email is required"
    },
    language_switch: 'Switch Language',
};

export default en;
