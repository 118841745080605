import Country from './SiteConstants/Country';
import Language from './SiteConstants/Language';

class SiteSettings {
    constructor({ countries, languages }) {
        // Map the raw data to your models
        this.countries = countries.map(country => new Country(country));
        this.languages = languages.map(language => new Language(language));
    }

    getAllCountries() {
        return this.countries;
    }

    getAllLanguages() {
        return this.languages;
    }
}

export default SiteSettings;
