import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

// Import JavaScript translation files
import en from './locale/en';
import hr from './locale/hr';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: {
                translation: en,
            },
            hr: {
                translation: hr,
            },
        },
        lng: 'en', // Default language
        fallbackLng: 'en', // Fallback language
        interpolation: {
            escapeValue: false, // React already does escaping
        },
    });

export default i18n;
