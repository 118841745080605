import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import MainButton from '../components/elements/Buttons/MainButton';
import LinkButton from '../components/elements/Buttons/LinkButton';
import MainTextInput from '../components/elements/Inputs/MainTextInput';
import AuthLayout from '../layouts/AuthLayout';
import ContainerTitle from '../components/elements/Labels/ContainerTitle';
import { login, GetMe, GetItems } from '../services/Api'; // Import necessary API functions
import { useAuth } from '../context/AuthProvider';

const LoginPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [isEmailValid, setIsEmailValid] = useState(true);
    const [isPasswordValid, setIsPasswordValid] = useState(true);
    const { setIsAuthenticated, setUser, setItems } = useAuth(); // Access auth context to set data
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleLogin = async () => {
        try {
            const response = await login(email, password);
            localStorage.setItem('jwtToken', response.token); // Store the token

            // Fetch user data and items after login
            const userData = await GetMe();
            setUser(userData); // Set user data in context

            const fetchedItems = await GetItems();
            setItems(fetchedItems); // Set items in context

            setIsAuthenticated(true); // Mark as authenticated
            navigate('/dashboard'); // Navigate to the main page (dashboard)
        } catch (error) {
            console.error('Login failed:', error);
            setIsEmailValid(false);
            setIsPasswordValid(false);
        }
    };

    return (
        <AuthLayout>
            <div style={styles.container}>
                <ContainerTitle title={t('login.title')} />

                <MainTextInput
                    inputType="text"
                    placeholder={t('login.emailPlaceholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    style={styles.input}
                    isValid={isEmailValid}
                />

                <MainTextInput
                    placeholder={t('login.passwordPlaceholder')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    inputType="password"
                    style={styles.input}
                    isValid={isPasswordValid}
                />

                <MainButton onClick={handleLogin} style={styles.button}>
                    {t('login.loginButton')}
                </MainButton>

                <div style={styles.linkContainer}>
                    <LinkButton onClick={() => navigate('/register')}>
                        {t('login.registerPrompt')}
                    </LinkButton>
                    <LinkButton onClick={() => navigate('/forgot-password')}>
                        {t('login.forgotPassword')}
                    </LinkButton>
                </div>
            </div>
        </AuthLayout>
    );
};

export default LoginPage;

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '100%',
        padding: '5%',
        boxSizing: 'border-box',
    },
    input: {
        marginBottom: '20px',
        width: '100%',
        fontSize: '1rem',
    },
    button: {
        width: '100%',
        marginBottom: '20px',
    },
    linkContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
};
