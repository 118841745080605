import React from 'react';
import { Outlet } from 'react-router-dom'; // Outlet will render nested routes
import { useTranslation } from 'react-i18next';
import './App.css';
import TopBar from './components/TopBar';
import BottomNav from './components/BottomNav';

function App() {
    const { i18n } = useTranslation();

    const switchLanguage = (lang) => {
        i18n.changeLanguage(lang);
    };

    const handleLogout = () => {
        localStorage.removeItem('jwtToken');
        window.location.href = '/login';
    };

    return (
        <div className="container">
            {/* Top Bar */}
            <TopBar onLogout={handleLogout} />
            {/* This will render the nested route (DashboardPage, ItemsPage, etc.) */}
            <div className="scrollableContent">
                <Outlet />
            </div>

            {/* Bottom Navigation */}
            <BottomNav />
        </div>
    );
}

export default App;
