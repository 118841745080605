import React from 'react';

const LinkButton = ({ children, onClick, customStyle = {} }) => {
    return (
        <button
            style={{ ...styles.button, ...customStyle }}
            onClick={onClick}
        >
            {children}
        </button>
    );
};

export default LinkButton;

const styles = {
    button: {
        color: '#6200ee', // Default text color
        fontSize: '16px',
        marginBottom: '10px',
        backgroundColor: 'transparent',
        border: 'none',
        cursor: 'pointer',
    },
};
