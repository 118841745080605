import React, { useEffect, useState } from 'react';
import { GetWarrantyPreview } from '../services/Api';
import { MdShield } from 'react-icons/md';
import { useTranslation } from 'react-i18next';

const WarrantyPreview = () => {
    const { t } = useTranslation();
    const [warranties, setWarranties] = useState([]);

    useEffect(() => {
        const fetchWarranties = async () => {
            try {
                const fetchedWarranties = await GetWarrantyPreview();
                setWarranties(fetchedWarranties);
            } catch (error) {
                console.error('Error fetching warranties:', error);
            }
        };

        fetchWarranties();
    }, []);

    return (
        <div style={styles.previewCard}>
            {warranties.length > 0 ? (
                <ul style={styles.warrantyList}>
                    {warranties.slice(0, 3).map((warranty) => (
                        <li key={warranty.id} style={styles.warrantyItem}>
                            <p style={styles.itemName}>{warranty.manufacturer_item?.product_name}</p>
                            <p style={styles.itemName}>{warranty.seller?.name}</p>
                            <p style={styles.expiryDate}>
                                {t('MainApp.warrantyPreview.expiry')}: <span style={styles.expiryDateText}>{new Date(warranty.expire_at).toLocaleDateString()}</span>
                            </p>
                        </li>
                    ))}
                </ul>
            ) : (
                <div style={styles.allUnderWarranty}>
                    <MdShield style={styles.shieldIcon} />
                    <p>{t('MainApp.warrantyPreview.allUnderWarranty')}</p>
                </div>
            )}
        </div>
    );
};

export default WarrantyPreview;

const styles = {
    previewCard: {
        height: '40vh',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '1rem',
        textAlign: 'center',
        overflowY: 'auto',
    },
    warrantyList: {
        listStyleType: 'none',
        padding: 0,
        margin: 0,
    },
    warrantyItem: {
        padding: '0.5rem',
        borderBottom: '1px solid #ddd',
        marginBottom: '0.5rem',
    },
    itemName: {
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    expiryDate: {
        fontSize: '0.9rem',
        color: '#555',
    },
    expiryDateText: {
        color: '#e67e22', // Orange color for expiry date
        fontWeight: 'bold',
    },
    allUnderWarranty: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        color: '#4CAF50', // Green color for text and icon
    },
    shieldIcon: {
        fontSize: '10rem',
        marginBottom: '0.5rem',
    },
};
