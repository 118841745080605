import React from 'react';
import { FlagIcon } from 'react-flag-kit';

const LanguageButton = ({ lang, onClick }) => {
    // Map language code to corresponding flag country code
    const flagMap = {
        en: 'US', // US flag for English
        hr: 'HR', // Croatia flag for Croatian
    };

    return (
        <button style={styles.button} onClick={() => onClick(lang)}>
            <FlagIcon code={flagMap[lang]} size={32} style={styles.flagIcon} />
        </button>
    );
};

export default LanguageButton;

const styles = {
    button: {
        borderRadius: '50%', // Circular shape
        width: '20vw', // 10% of the viewport width for the button
        height: '20vw', // Keep the button a circle, height equals width
        border: '0.3vw solid #6200ee', // Responsive border based on viewport width
        backgroundColor: 'white',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        cursor: 'pointer',
        margin: '2vw', // Margin relative to the viewport width
    },
    flagIcon: {
        width: '60%', // Flag scales with the button
        height: '60%',
    },
};
