const hr = {
    MainApp: {
        Navigation: {
            home: 'Početna',
            items: 'Artikli',
            card: 'Kartica',
            campaigns: 'Kampanje',
            profile: 'Profil',
        },
        BottomNav: {
            home: "Početna",
            items: "Stavke",
            card: "Kartica",
            campaigns: "Kampanje",
            profile: "Profil",
            qrTitle: 'Vaš QR kod',
            close: 'Zatvori',
        },
        Menu: {
            warranty: 'Upravljanje digitalnim jamstvima',
            items: 'Upravljanje artiklima',
            campaigns: 'Aktivne kampanje',
            organizations: 'Organizacije',
            points: 'Bodovi',
            settings: 'Postavke',
            appSettings: 'Postavke aplikacije',
            notifications: 'Obavijesti',
            inviteFriends: 'Pozovi prijatelje',
            contact: 'Kontakt',
            logout: 'Odjava',
        },
        Dashboard: {
            warranty: 'Upravljajte svojim digitalnim jamstvima ovdje.',
            items: 'Pratite svoje artikle.',
            campaigns: 'Pogledajte aktivne kampanje.',
            organizations: 'Upravljajte organizacijama.',
            settings: 'Prilagodite postavke aplikacije.',
        },
        Items: {
            title: "Upravljanje Vašim Artiklama",
            description: "Ovdje možete pronaći detalje o vašim kupljenim artiklima.",
            selectManufacturer: "Odaberite Proizvođača",
            selectSeller: "Odaberite Prodavača",
            priceUnavailable: "Cijena nije dostupna",
            purchaseDate: "Datum kupnje",
            noItems: "Nema pronađenih artikala.",
            underWarranty: "U jamstvu",
            warrantyNearExpiry: "Jamstvo uskoro istječe",
            warrantyExpired: "Jamstvo je isteklo",
        },
        "Promotions": {
            "loading": "Učitavanje promocija...",
            "name": "Naziv promocije",
            "description": "Opis",
            "organization": "Organizacija"
        },
        "pointsPreview": {
            "allPoints": "Ukupni bodovi",
            "organizationPoints": "Bodovi"
        },
        "warrantyPreview": {
            "expiry": "Datum isteka garancije",
            "allUnderWarranty": "Svi predmeti su trenutno pod garancijom"
        }
    },
    splash: {
        login: 'Prijava',
        register: 'Registracija',
        checkingAuth: 'Provjera autentikacije...',
    },
    login: {
        title: "Prijava",
        emailPlaceholder: "Email",
        passwordPlaceholder: "Lozinka",
        loginButton: "Prijavi se",
        registerPrompt: "Nemate račun? Registrirajte se ovdje.",
        forgotPassword: "Zaboravljena lozinka?",
    },
    "register": {
        "title": "Registracija",
        "emailPlaceholder": "Email",
        "passwordPlaceholder": "Lozinka",
        "confirmPasswordPlaceholder": "Potvrdi lozinku",
        "registerButton": "Registriraj se",
        "loginPrompt": "Već imate račun? Prijavite se",
        "passwordMismatch": "Lozinke se ne podudaraju"
    },
    "forgotPassword": {
        "title": "Zaboravljena lozinka",
        "emailPlaceholder": "Unesite svoj email",
        "sendButton": "Pošalji",
        "backButton": "Nazad",
        "emailRequired": "Email je obavezan"
    },
    language_switch: 'Promijeni jezik',
};

export default hr;
