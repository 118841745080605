import React, { createContext, useState, useEffect, useContext } from 'react';
import { GetMe, GetItems } from '../services/Api';

// Create a context to share authentication status and user data across the app
const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext); // Custom hook to use AuthContext

const AuthProvider = ({ children }) => {
    const [isAuthenticated, setIsAuthenticated] = useState(false);
    const [user, setUser] = useState(null); // To store user data
    const [items, setItems] = useState([]); // To store items
    const [loading, setLoading] = useState(true); // To handle loading state

    useEffect(() => {
        const checkAuth = async () => {
            const token = localStorage.getItem('jwtToken');
            if (token) {
                try {
                    // Fetch the user data if the token exists
                    const userData = await GetMe();
                    setUser(userData); // Set user data in state
                    setIsAuthenticated(true); // Mark as authenticated
                    await fetchUserItems();
                } catch (error) {
                    console.error('Error fetching user data:', error);
                    setIsAuthenticated(false); // Mark as not authenticated if error occurs
                }
            }
            setLoading(false); // Loading finished
        };

        const fetchUserItems = async () => {
            try {
                const fetchedItems = await GetItems();
                setItems(fetchedItems); // Set items in state
            } catch (error) {
                console.error('Error fetching items:', error);
            }
        };

        checkAuth();
    }, []);

    if (loading) {
        return <div>Loading...</div>; // Show a loading state while fetching user data
    }

    return (
        <AuthContext.Provider value={{ isAuthenticated, user, items, setIsAuthenticated, setUser, setItems }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
