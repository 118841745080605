import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../layouts/AuthLayout';
import ContainerTitle from '../components/elements/Labels/ContainerTitle';
import MainButton from '../components/elements/Buttons/MainButton';
import MainTextInput from '../components/elements/Inputs/MainTextInput';

const RegisterPage = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleRegister = () => {
        if (password !== confirmPassword) {
            console.log(t('register.errors.passwordMismatch'));
            return;
        }

        console.log(t('register.success'), { email, password });
        // Implement the registration API call here if needed
    };

    const handleLoginRedirect = () => {
        navigate('/login');
    };

    return (
        <AuthLayout>
            <div style={styles.container}>
                <ContainerTitle title={t('register.title')} />

                <MainTextInput
                    inputType="email"
                    placeholder={t('register.emailPlaceholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    autoComplete="email"
                    style={styles.input}
                />
                <MainTextInput
                    inputType="password"
                    placeholder={t('register.passwordPlaceholder')}
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    autoComplete="new-password"
                    style={styles.input}
                />
                <MainTextInput
                    inputType="password"
                    placeholder={t('register.confirmPasswordPlaceholder')}
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    autoComplete="new-password"
                    style={styles.input}
                />

                <MainButton onClick={handleRegister} style={styles.button}>
                    {t('register.registerButton')}
                </MainButton>

                <button style={styles.link} onClick={handleLoginRedirect}>
                    {t('register.loginRedirect')}
                </button>
            </div>
        </AuthLayout>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        height: '100%',
    },
    input: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: '20px',
    },
    button: {
        width: '100%',
        maxWidth: '400px',
        marginBottom: '10px',
    },
    link: {
        marginTop: '10px',
        color: 'blue',
        textAlign: 'center',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        fontSize: '16px',
    },
};

export default RegisterPage;
