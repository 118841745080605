export default class Language {
    constructor({ id, name, code }) {
        this.id = id;
        this.name = name;
        this.code = code;
    }

    // Optional method for additional logic if needed
    getDisplayName() {
        return `${this.name} (${this.code})`;
    }
}
