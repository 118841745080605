import React, { useState } from 'react';
import gsoftLogo from '../assets/images/gsoftlogo.png';
import { IoMenu, IoShieldCheckmark, IoCube, IoMegaphone, IoBusiness, IoWallet, IoSettings, IoNotifications, IoPeople, IoMail, IoLogOut } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';

const TopBar = ({ onLogout }) => {
    const { t } = useTranslation();
    const [isMenuVisible, setIsMenuVisible] = useState(false);

    const toggleMenu = () => {
        setIsMenuVisible(!isMenuVisible);
    };

    return (
        <>
            <div style={styles.topBar}>
                <img src={gsoftLogo} alt="Logo" style={styles.logo} />
                <button onClick={toggleMenu} style={styles.menuButton}>
                    <IoMenu size={24} style={styles.menuIcon} />
                </button>
            </div>

            {isMenuVisible && (
                <div style={styles.menuOverlay} onClick={toggleMenu}>
                    <div style={styles.menu}>
                        <div style={styles.menuItem}>
                            <IoShieldCheckmark style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.warranty')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoCube style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.items')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoMegaphone style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.campaigns')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoBusiness style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.organizations')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoWallet style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.points')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoSettings style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.settings')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoSettings style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.appSettings')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoNotifications style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.notifications')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoPeople style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.inviteFriends')}</span>
                        </div>
                        <div style={styles.menuItem}>
                            <IoMail style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.contact')}</span>
                        </div>
                        <div style={{ ...styles.menuItem, ...styles.logoutItem }} onClick={onLogout}>
                            <IoLogOut style={styles.menuIcon} />
                            <span>{t('MainApp.Menu.logout')}</span>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default TopBar;

const styles = {
    topBar: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        zIndex: 1,
    },
    logo: {
        height: '40px',
    },
    menuButton: {
        background: 'none',
        border: 'none',
        cursor: 'pointer',
    },
    menuIcon: {
        color: '#6200ee',
        marginRight: '10px',
    },
    menuOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100vw',
        height: '100vh',
        backgroundColor: 'rgba(0, 0, 0, 0.7)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    menu: {
        backgroundColor: '#fff',
        width: '90%',
        maxWidth: '500px',
        padding: '20px',
        borderRadius: '10px',
        textAlign: 'left',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)',
    },
    menuItem: {
        display: 'flex',
        alignItems: 'center',
        fontSize: '1.1rem',
        color: '#333',
        padding: '12px 0',
        cursor: 'pointer',
        borderBottom: '1px solid #ddd',
    },
    logoutItem: {
        color: '#e74c3c',
        borderBottom: 'none',
    },
};
