import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {I18nextProvider} from "react-i18next";
import i18n from "./lang/i18n";
import DeviceWrapper from "./wrappers/DeviceWrapper";
import {SettingsProvider} from "./context/SettingsContext";
import LanguageProvider from "./providers/LanguageProvider";
import AuthProvider from "./context/AuthProvider";
import LoginPage from "./pages/LoginPage";
import AppRouter from "./AppRouter";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <SettingsProvider>
            <LanguageProvider>
                <I18nextProvider i18n={i18n}>
                    <AuthProvider fallback={<LoginPage />}>
                        <AppRouter />
                    </AuthProvider>
                </I18nextProvider>
            </LanguageProvider>
    </SettingsProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
