import React, { useState } from 'react';
import { IoEyeOutline, IoEyeOffOutline } from 'react-icons/io5'; // Import eye icons

const MainTextInput = ({
                           placeholder,
                           value,
                           onChange,
                           inputType = 'text',
                           isValid = true,
                           customStyle = {},
                       }) => {
    const [isFocused, setIsFocused] = useState(false);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);

    // Toggle password visibility
    const togglePasswordVisibility = () => {
        setIsPasswordVisible(!isPasswordVisible);
    };

    return (
        <div style={{ ...styles.inputWrapper, ...customStyle }}>
            <input
                type={inputType === 'password' && !isPasswordVisible ? 'password' : 'text'}
                placeholder={placeholder}
                value={value}
                onChange={onChange}
                onFocus={() => setIsFocused(true)}
                onBlur={() => setIsFocused(false)}
                style={{
                    ...styles.input,
                    ...(isFocused ? styles.focusedInput : {}),
                    ...(isValid ? {} : styles.invalidInput),
                }}
            />
            {inputType === 'password' && (
                <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={styles.eyeButton}
                >
                    {isPasswordVisible ? <IoEyeOffOutline size={20} /> : <IoEyeOutline size={20} />}
                </button>
            )}
        </div>
    );
};

export default MainTextInput;

const styles = {
    inputWrapper: {
        position: 'relative', // For positioning the eye icon inside the input field
        width: '100%',
        marginBottom: '1.5em',
    },
    input: {
        height: '3rem',
        borderStyle: 'solid',
        borderWidth: '1px',
        borderColor: '#ccc',
        padding: '0.8rem 1rem',
        borderRadius: '0.5rem',
        width: '100%',
        fontSize: '1rem',
        boxSizing: 'border-box',
    },
    eyeButton: {
        position: 'absolute',
        right: '1rem', // Position the eye icon on the right
        top: '50%',
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        cursor: 'pointer',
        color: '#6200ee', // Optional: add brand color to the icon
    },
    focusedInput: {
        borderColor: '#6200ee',
        borderWidth: '2px',
    },
    invalidInput: {
        borderColor: 'red',
    },
};
