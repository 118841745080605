import React, { useState, useEffect } from 'react';
import i18n from '../lang/i18n'; // Adjust the path to your i18n configuration
import LanguageButton from '../components/elements/Buttons/LanguageButton'; // Import the LanguageButton
import ContainerTitle from '../components/elements/Labels/ContainerTitle'; // Import the ContainerTitle

const LanguageProvider = ({ children }) => {
    const [language, setLanguage] = useState(null);

    useEffect(() => {
        // Check if a language is already set in localStorage
        const savedLanguage = localStorage.getItem('language');
        if (savedLanguage) {
            setLanguage(savedLanguage);
            i18n.changeLanguage(savedLanguage); // Initialize i18n with the saved language
        }
    }, []);

    const handleLanguageSelect = (lang) => {
        setLanguage(lang);
        localStorage.setItem('language', lang);
        i18n.changeLanguage(lang); // Set the language in i18n
    };

    // If no language is selected, show the language selection UI
    if (!language) {
        return (
            <div style={styles.container}>
                <ContainerTitle title="Select Your Language" />
                <div style={styles.buttonContainer}>
                    <LanguageButton lang="en" onClick={handleLanguageSelect} />
                    <LanguageButton lang="hr" onClick={handleLanguageSelect} />
                </div>
            </div>
        );
    }

    // If language is already set, render the children
    return <>{children}</>;
};

// Inline styles for the language selection screen
const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100vh',
        textAlign: 'center',
    },
    buttonContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        gap: '2vw', // Make the gap between buttons responsive
        marginTop: '2vw',
    },
};

export default LanguageProvider;
