import React from 'react';

const ContainerTitle = ({ title }) => {
    return <h1 style={styles.title}>{title}</h1>;
};

export default ContainerTitle;

const styles = {
    title: {
        fontSize: '8vw', // Font size responsive to viewport width
        fontWeight: 'bold',
        color: '#6200ee',
        marginBottom: '2vw', // Margin relative to the viewport
        textAlign: 'center',
    },
};
