import React, { useState } from 'react';

const MainButton = ({ children, onClick, customStyle = {} }) => {
    const [isPressed, setIsPressed] = useState(false);

    return (
        <button
            style={{
                ...styles.button,
                ...(isPressed ? styles.buttonPressed : {}),
                ...customStyle,
            }}
            onMouseDown={() => setIsPressed(true)}   // Pressed effect
            onMouseUp={() => setIsPressed(false)}    // Release effect
            onMouseLeave={() => setIsPressed(false)} // Ensure state is reset if mouse leaves
            onClick={onClick}
        >
            <span style={isPressed ? styles.buttonTextPressed : styles.buttonText}>
                {children}
            </span>
        </button>
    );
};

export default MainButton;

const styles = {
    button: {
        backgroundColor: '#6200ee',
        padding: '1em 2em', // Use relative units for better responsiveness
        borderRadius: '1em',
        marginBottom: '1.5em',
        border: 'none',
        cursor: 'pointer',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        transition: 'background-color 0.2s ease', // Smooth transition
    },
    buttonPressed: {
        backgroundColor: 'white',
        border: '2px solid #6200ee',
    },
    buttonText: {
        fontSize: '1rem', // Relative unit for text size
        color: 'white',
        transition: 'color 0.2s ease', // Smooth transition for text color
    },
    buttonTextPressed: {
        color: '#6200ee',
    },
};
