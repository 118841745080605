import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { GetPointsPreview, GetWarrantyPreview } from '../services/Api';
import PromotionsPreview from '../components/PromotionsPreview';
import DiscountsPreview from '../components/DiscountsPreview';
import PointsPreview from '../components/PointsPreview';
import WarrantyPreview from '../components/WarrantyPreview';

const DashboardPage = () => {
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);
    const [promotions, setPromotions] = useState(null);
    const [discounts, setDiscounts] = useState(null);
    const [points, setPoints] = useState(null);
    const [warranty, setWarranty] = useState(null);

    useEffect(() => {
        const fetchData = async () => {
            try {

            } catch (error) {
                console.error("Error fetching dashboard data:", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <div>Loading...</div>;
    }

    return (
        <div style={styles.dashboardContainer}>
            <div style={styles.previewsContainer}>
                <PromotionsPreview />
                <DiscountsPreview />
                <PointsPreview />
                <WarrantyPreview />
            </div>
        </div>
    );
};

export default DashboardPage;

// CSS-in-JS styles
const styles = {
    dashboardContainer: {
        padding: '1rem',
    },
    previewsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    }
};
