import axios from 'axios';
import {jwtDecode} from 'jwt-decode'; // Correct import for jwtDecode

// Define the API base URL (you can use a .env file for this as well)
const API_URL = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({
    baseURL: API_URL,
    headers: {
        'Content-Type': 'application/json',
    },
});

// Helper function to get token from localStorage
const getToken = () => {
    try {
        const token = localStorage.getItem('jwtToken');
        return token;
    } catch (error) {
        console.error('Failed to retrieve token from storage', error);
        return null;
    }
};

// Helper function to save the token to localStorage
const saveToken = (token) => {
    try {
        localStorage.setItem('jwtToken', token);
    } catch (error) {
        console.error('Failed to save token to storage', error);
    }
};

// Check if the token is expired
const isTokenExpired = (token) => {
    try {
        const decoded = jwtDecode(token);
        const currentTime = Date.now() / 1000; // Current time in seconds
        return decoded.exp < currentTime; // Check if token is expired
    } catch (error) {
        console.error('Error decoding token', error);
        return true; // Treat as expired if an error occurs
    }
};

// Helper function to save user data to localStorage
const saveUserData = (userData) => {
    try {
        localStorage.setItem('userData', JSON.stringify(userData));
    } catch (error) {
        console.error('Failed to save user data to storage', error);
    }
};

// Function to fetch the authenticated user's data
export const GetMe = async () => {
    try {
        const response = await axiosInstance.get('/me');
        const userData = response.data;
        saveUserData(userData); // Save user data to local storage
        return userData;
    } catch (error) {
        console.error('Error fetching user data from /me', error);
        throw error;
    }
};
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
    failedQueue.forEach(prom => {
        if (error) {
            prom.reject(error);
        } else {
            prom.resolve(token);
        }
    });

    failedQueue = [];
};

// Function to refresh the token
const refreshAuthToken = async () => {
    try {
        const token = getToken();

        if (!token) return null;

        // Prevent multiple refreshes by queuing requests
        if (isRefreshing) {
            return new Promise((resolve, reject) => {
                failedQueue.push({ resolve, reject });
            });
        }

        isRefreshing = true;

        const response = await axios.post(API_URL + '/refresh', { token }, {
            headers: {
                'Content-Type': 'application/json',
            }
        });

        const newToken = response.data.token;
        saveToken(newToken);
        processQueue(null, newToken);
        return newToken;

    } catch (error) {
        processQueue(error, null);
        console.error('Error refreshing token', error);
        return null;
    } finally {
        isRefreshing = false;
    }
};

// Request interceptor to add the auth token to requests
axiosInstance.interceptors.request.use(
    async (config) => {
        let token = getToken();
        if (token && isTokenExpired(token)) {
            token = await refreshAuthToken(); // Refresh the token if expired
        }

        if (token) {
            config.headers['Authorization'] = 'Bearer ' + token; // Append token
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

// Response interceptor to handle 401 errors and retry with refreshed token
axiosInstance.interceptors.response.use(
    (response) => {
        return response; // Return successful responses
    },
    async (error) => {
        // Just handle the error without attempting to refresh the token
        return Promise.reject(error);
    }
);

// Function to clear auth data (logout scenario)
const clearAuthData = async () => {
    try {
        localStorage.removeItem('jwtToken');
        // Optionally, redirect to login screen using navigation
    } catch (error) {
        console.error('Failed to clear auth data', error);
    }
};

// Function to fetch site settings
export const fetchSiteSettings = async () => {
    try {
        const response = await axiosInstance.get('/settings');
        return response.data;
    } catch (error) {
        console.error('Error fetching site settings', error);
        throw error;
    }
};

// Login function
export const login = async (email, password) => {
    try {
        const response = await axiosInstance.post('/login', { email, password });
        return response.data;
    } catch (error) {
        console.error('Login error:', error.response);
        throw error;
    }
};

// Refresh token function
export const refresh = async (token) => {
    try {
        const response = await axiosInstance.post('/refresh', { token });
        return response.data;
    } catch (error) {
        console.error('Auth error:', error.response);
        throw error;
    }
};


export const GetItems = async () => {
    try {
        const response = await axiosInstance.get('/user/items');
        const items = response.data;
        return items;
    } catch (error) {
        console.error('Error fetching user data from /me', error);
        throw error;
    }
};

export const GetPromotionsPreview = async () => {
    try {
        const response = await axiosInstance.get('/user/promotions-preview');
        const promotions = response.data;
        return promotions;
    } catch (error) {
        console.error('Error fetching user data from /me', error);
        throw error;
    }
};

export const GetPointsPreview = async () => {
    try {
        const response = await axiosInstance.get('/user/points-preview');
        const points = response.data;
        return points;
    } catch (error) {
        console.error('Error fetching user data from /me', error);
        throw error;
    }
};

export const GetDiscountsPreview = async () => {
    try {
        const response = await axiosInstance.get('/user/discounts-preview');
        const discounts = response.data;
        return discounts;
    } catch (error) {
        console.error('Error fetching user data from /me', error);
        throw error;
    }
};

export const GetWarrantyPreview = async () => {
    try {
        const response = await axiosInstance.get('/user/warranties-preview');
        const warranties = response.data;
        return warranties;
    } catch (error) {
        console.error('Error fetching user data from /me', error);
        throw error;
    }
};

export default axiosInstance;
