import React, { useEffect, useState } from 'react';
import gsoftIcon from '../assets/images/gsofticon.png';
import { GetPointsPreview } from '../services/Api';
import { useTranslation } from 'react-i18next';

const PointsPreview = () => {
    const { t } = useTranslation();
    const [pointsData, setPointsData] = useState({ allPoints: 0, belongingOrgPoints: { organization: { name: '' }, points: 0 } });

    useEffect(() => {
        const fetchPointsData = async () => {
            try {
                const data = await GetPointsPreview();
                setPointsData(data);
            } catch (error) {
                console.error('Error fetching points data:', error);
            }
        };

        fetchPointsData();
    }, []);

    return (
        <div style={styles.previewCard}>
            {/* Left Side */}
            <div style={styles.leftSide}>
                <img src={gsoftIcon} alt="GSoft Icon" style={styles.icon} />
                <p style={styles.pointsText}>
                    {t('MainApp.pointsPreview.allPoints')}: {pointsData.allPointsSum}
                </p>
            </div>

            {/* Right Side */}
            <div style={styles.rightSide}>
                <h3 style={styles.orgName}>{pointsData.belongingOrgPoints.organization.name}</h3>
                <p style={styles.orgPoints}>
                    {t('MainApp.pointsPreview.organizationPoints')}: {pointsData.belongingOrgPoints.points}
                </p>
            </div>
        </div>
    );
};

export default PointsPreview;

const styles = {
    previewCard: {
        height: '25vh',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '1rem',
        textAlign: 'center',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    leftSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '40%',
    },
    icon: {
        width: '50px',
        height: '50px',
        marginBottom: '0.5rem',
    },
    pointsText: {
        fontSize: '1rem',
        fontWeight: 'bold',
    },
    rightSide: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '60%',
    },
    orgName: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
    },
    orgPoints: {
        fontSize: '1rem',
        color: '#333',
    },
};
