import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../context/AuthProvider';
import { FaFilter } from 'react-icons/fa';

const ItemsPage = () => {
    const { t } = useTranslation();
    const { items } = useAuth(); // Access items from AuthContext
    const { belonging_items, manufacturers, sellers } = items; // Destructure items to get nested data

    const [filteredItems, setFilteredItems] = useState(belonging_items);
    const [selectedManufacturer, setSelectedManufacturer] = useState('');
    const [selectedSeller, setSelectedSeller] = useState('');
    const [showFilters, setShowFilters] = useState(false);

    useEffect(() => {
        handleFilterChange(); // Apply filters whenever state changes
    }, [selectedManufacturer, selectedSeller]);

    const handleFilterChange = () => {
        let filtered = belonging_items;
        if (selectedManufacturer) {
            filtered = filtered.filter(item => item.manufacturer_id === selectedManufacturer);
        }
        if (selectedSeller) {
            filtered = filtered.filter(item => item.seller_id === selectedSeller);
        }
        setFilteredItems(filtered);
    };

    const handleManufacturerChange = (e) => {
        setSelectedManufacturer(e.target.value);
    };

    const handleSellerChange = (e) => {
        setSelectedSeller(e.target.value);
    };

    // Function to check the warranty status
    const getWarrantyStatus = (item) => {
        const { start_date, manufacturer_item } = item;
        const warrantyDuration = manufacturer_item?.warranty_duration; // in months
        if (!start_date || !warrantyDuration) return 'expired';

        const startDate = new Date(start_date);
        const currentDate = new Date();
        const warrantyEndDate = new Date(startDate.setMonth(startDate.getMonth() + warrantyDuration));
        const remainingMonths = (warrantyEndDate - currentDate) / (1000 * 60 * 60 * 24 * 30); // Convert milliseconds to months

        if (currentDate > warrantyEndDate) {
            return 'expired';
        } else if (remainingMonths <= 6) {
            return 'nearExpiry';
        } else {
            return 'active';
        }
    };

    return (
        <div style={styles.pageContainer}>
            <div style={styles.filterIconContainer}>
                <FaFilter style={styles.filterIcon} onClick={() => setShowFilters(!showFilters)} />
            </div>
            {showFilters && (
                <div style={styles.filterSection}>
                    <select value={selectedManufacturer} onChange={handleManufacturerChange} style={styles.selectBox}>
                        <option value="">{t('MainApp.Items.selectManufacturer')}</option>
                        {manufacturers && manufacturers.map(manufacturer => (
                            <option key={manufacturer.id} value={manufacturer.id}>
                                {manufacturer.name}
                            </option>
                        ))}
                    </select>
                    <select value={selectedSeller} onChange={handleSellerChange} style={styles.selectBox}>
                        <option value="">{t('MainApp.Items.selectSeller')}</option>
                        {sellers && sellers.map(seller => (
                            <option key={seller.id} value={seller.id}>
                                {seller.name}
                            </option>
                        ))}
                    </select>
                </div>
            )}
            <div style={styles.itemsContainer}>
                {filteredItems && filteredItems.length > 0 ? (
                    filteredItems.map(item => (
                        <div key={item.id} style={styles.itemCard}>
                            <img
                                src={item.manufacturer_item?.image_url}
                                alt={item.manufacturer_item?.product_name}
                                style={styles.itemImage}
                            />
                            <div style={styles.itemDetails}>
                                <span style={styles.productName}>{item.manufacturer_item?.product_name}</span>
                                <span style={styles.detailText}>
                                    {item.seller?.name}, {item.seller_venue?.city}, {item.seller_venue?.address}
                                </span>
                                <span style={styles.detailText}>
                                    {new Date(item.latest_transaction.date_of_transaction).toLocaleDateString()}
                                </span>
                                <span style={styles.priceText}>
                                    {item.latest_transaction?.price ? `€${item.latest_transaction.price}` : t('MainApp.Items.priceUnavailable')}
                                </span>
                                <span style={
                                    getWarrantyStatus(item) === 'active' ? styles.warrantyActive :
                                        getWarrantyStatus(item) === 'nearExpiry' ? styles.warrantyNearExpiry :
                                            styles.warrantyExpired
                                }>
                                    {getWarrantyStatus(item) === 'active' ? t('MainApp.Items.underWarranty') :
                                        getWarrantyStatus(item) === 'nearExpiry' ? t('MainApp.Items.warrantyNearExpiry') :
                                            t('MainApp.Items.warrantyExpired')}
                                </span>
                            </div>
                        </div>
                    ))
                ) : (
                    <p style={styles.noItemsMessage}>{t('MainApp.Items.noItems')}</p>
                )}
            </div>
        </div>
    );
};

export default ItemsPage;

// CSS-in-JS styles for the component
const styles = {
    pageContainer: {
        maxWidth: '100vw',
        padding: '0 1rem',
    },
    filterIconContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        cursor: 'pointer',
        marginBottom: '0.5rem',
    },
    filterIcon: {
        fontSize: '1.5rem',
        marginLeft: '0.5rem',
    },
    filterSection: {
        display: 'flex',
        justifyContent: 'space-between',
        gap: '1rem',
        flexWrap: 'wrap',
        marginBottom: '1rem',
    },
    selectBox: {
        padding: '0.5rem',
        borderRadius: '4px',
        border: '1px solid #ccc',
        fontSize: '1rem',
        flex: 1,
        maxWidth: '48%',
    },
    itemsContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: '1rem',
    },
    itemCard: {
        display: 'flex',
        alignItems: 'center',
        padding: '0.5rem 1rem',
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        height: '10vh',
    },
    itemImage: {
        height: '100%',
        width: 'auto',
        borderRadius: '8px',
        marginRight: '1rem',
    },
    itemDetails: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        height: '100%',
        lineHeight: '1.2rem',
    },
    productName: {
        fontSize: '1rem',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    detailText: {
        fontSize: '0.9rem',
        color: '#555',
    },
    priceText: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        color: '#000',
    },
    warrantyActive: {
        fontSize: '0.9rem',
        color: '#4caf50', // Green color for active warranty
        fontWeight: 'bold',
    },
    warrantyNearExpiry: {
        fontSize: '0.9rem',
        color: '#ff9800', // Orange color for near-expiry warranty
        fontWeight: 'bold',
    },
    warrantyExpired: {
        fontSize: '0.9rem',
        color: '#f44336', // Red color for expired warranty
        fontWeight: 'bold',
    },
    noItemsMessage: {
        textAlign: 'center',
        padding: '2rem 0', // Adds padding around the message
        fontSize: '1.2rem',
        color: '#888', // Muted color to indicate no items found
    }
};
