import React from 'react';
import AuthLogo from '../../src/components/elements/Logo/AuthLogo';

const AuthLayout = ({ children }) => (
    <div style={styles.container}>
        <div style={styles.logoSection}>
            <AuthLogo />
        </div>
        <div style={styles.contentSection}>{children}</div>
    </div>
);

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logoSection: {
        height: '20%',
    },
    contentSection: {
        height: '80%',
        width: '90vw',  // Make width relative to viewport width
        maxWidth: '100%', // Ensure the width doesn't exceed 100% on small devices
        padding: '5vw',  // Use viewport width for padding, making it responsive
        boxSizing: 'border-box', // Ensure padding is included within the element's width
    },
};

export default AuthLayout;
