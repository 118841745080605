export default class Country {
    constructor({ id, name, iso, iso3, numcode, phonecode }) {
        this.id = id;
        this.name = name;
        this.iso = iso;
        this.iso3 = iso3;
        this.numcode = numcode;
        this.phonecode = phonecode;
    }
}
