import React, { useEffect, useState } from 'react';
import { GetPromotionsPreview } from '../services/Api';
import { useTranslation } from 'react-i18next';

const PromotionsPreview = () => {
    const { t } = useTranslation();
    const [showingPromotions, setShowingPromotions] = useState([]);
    const [currentPromotion, setCurrentPromotion] = useState(null);
    const colors = ['#f4a261', '#2a9d8f', '#e76f51', '#01b2fc', '#e9c46a'];

    useEffect(() => {
        // Fetch initial promotions when the component mounts
        fetchPromotions();

        // Start the interval for displaying promotions
        const interval = setInterval(() => {
            shiftPromotion();
        }, 6000); // Change promotion every 6 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        // Fetch more promotions when there is only one left in the array
        if (showingPromotions.length === 1) {
            fetchPromotions();
        }
    }, [showingPromotions]);

    const fetchPromotions = async () => {
        try {
            const newPromotions = await GetPromotionsPreview();
            if (newPromotions.length > 0) {
                setShowingPromotions((prev) => {
                    const combined = [...prev, ...newPromotions];
                    if (!currentPromotion) {
                        setCurrentPromotion(combined[0]); // Set the first promotion as the current if none is set
                    }
                    return combined;
                });
            }
        } catch (error) {
            console.error('Error fetching promotions:', error);
        }
    };

    const shiftPromotion = () => {
        setShowingPromotions((prevPromotions) => {
            if (prevPromotions.length > 0) {
                const updatedPromotions = [...prevPromotions];
                const nextPromotion = updatedPromotions.shift(); // Remove the first promotion
                setCurrentPromotion(nextPromotion); // Set the next promotion as the current one
                return updatedPromotions;
            }
            return prevPromotions;
        });
    };

    if (!currentPromotion) {
        return <div>{t('MainApp.Promotions.loading')}</div>;
    }

    return (
        <div style={{ ...styles.promotionCard, backgroundColor: getRandomColor() }}>
            <h3>{t('MainApp.Promotions.name')}: {currentPromotion.name}</h3>
            <p>{t('MainApp.Promotions.description')}: {currentPromotion.description}</p>
            <p>{t('MainApp.Promotions.organization')}: {currentPromotion.organization?.name}</p>
        </div>
    );
};

export default PromotionsPreview;

// CSS-in-JS styles
const styles = {
    promotionCard: {
        height: '25vh',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '1rem',
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        transition: 'background-color 0.5s ease',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        color: '#fff', // Ensure text is visible against colored background
    }
};

// Helper function to get a random background color
const getRandomColor = () => {
    const colors = ['#f4a261', '#2a9d8f', '#e76f51', '#01b2fc', '#e9c46a'];
    return colors[Math.floor(Math.random() * colors.length)];
};
