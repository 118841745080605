import React from 'react';
import gsoftLogo from '../../../assets/images/gsoftlogo.png'; // Adjust the path to your logo

const AuthLogo = () => {
    return (
        <div style={styles.logoContainer}>
            <img
                src={gsoftLogo}
                alt="GSoft Logo"
                style={styles.logo}
            />
        </div>
    );
};

export default AuthLogo;

const styles = {
    logoContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding:'5%'
    },
    logo: {
        maxWidth: '60%',
        maxHeight: '15%',
        objectFit: 'contain',
    },
};
