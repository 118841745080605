import React, { useState } from 'react';
import { IoHomeOutline, IoCubeOutline, IoPersonOutline, IoMegaphoneOutline } from 'react-icons/io5';
import { FaBarcode } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import { useNavigate, useLocation } from 'react-router-dom';
import Modal from 'react-modal';
import { QRCodeCanvas } from 'qrcode.react';
import JsBarcode from 'jsbarcode';
import { useAuth } from "../context/AuthProvider";

const BottomNav = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [activeTab, setActiveTab] = useState('qr'); // State to track active tab
    const { user } = useAuth(); // Assuming user data is available in AuthProvider

    const openModal = () => {
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
    };

    const renderBarcode = (canvasRef, value) => {
        if (canvasRef && value) {
            JsBarcode(canvasRef, value, { format: "CODE128" });
        }
    };

    const handleTabClick = (tab) => {
        setActiveTab(tab);
    };

    const isActive = (path) => location.pathname === path;

    const getNavItemStyle = (path) => {
        return isActive(path) ? { color: 'rgba(130, 22, 146, 0.7)' } : {};
    };

    return (
        <div style={styles.bottomNav}>
            <div style={styles.navItem} onClick={() => navigate('/')}>
                <IoHomeOutline style={{ ...styles.navIcon, ...getNavItemStyle('/') }} />
                <p style={{ ...styles.navText, ...getNavItemStyle('/home') }}>{t('MainApp.BottomNav.home')}</p>
            </div>
            <div style={styles.navItem} onClick={() => navigate('/items')}>
                <IoCubeOutline style={{ ...styles.navIcon, ...getNavItemStyle('/items') }} />
                <p style={{ ...styles.navText, ...getNavItemStyle('/items') }}>{t('MainApp.BottomNav.items')}</p>
            </div>
            <div style={styles.cardNavItem} onClick={openModal}>
                <FaBarcode style={{ ...styles.navIcon, ...getNavItemStyle('/card') }} />
                <p style={{ ...styles.navText, ...getNavItemStyle('/card') }}>{t('MainApp.BottomNav.card')}</p>
            </div>
            <div style={styles.navItem} onClick={() => navigate('/campaigns')}>
                <IoMegaphoneOutline style={{ ...styles.navIcon, ...getNavItemStyle('/campaigns') }} />
                <p style={{ ...styles.navText, ...getNavItemStyle('/campaigns') }}>{t('MainApp.BottomNav.campaigns')}</p>
            </div>
            <div style={styles.navItem} onClick={() => navigate('/profile')}>
                <IoPersonOutline style={{ ...styles.navIcon, ...getNavItemStyle('/profile') }} />
                <p style={{ ...styles.navText, ...getNavItemStyle('/profile') }}>{t('MainApp.BottomNav.profile')}</p>
            </div>

            {/* QR Code and Barcode Modal with Tabs */}
            <Modal
                isOpen={isModalOpen}
                onRequestClose={closeModal}
                style={modalStyles}
                contentLabel="QR Code Modal"
                ariaHideApp={false} // Avoids warning if using in a single-page app
            >
                <div style={styles.modalContent}>
                    <button style={styles.closeButton} onClick={closeModal}>X</button>
                    <h2>{t('MainApp.BottomNav.qrTitle')}</h2>

                    {/* Tabs */}
                    <div style={styles.tabsContainer}>
                        <button
                            style={activeTab === 'qr' ? styles.activeTab : styles.tab}
                            onClick={() => handleTabClick('qr')}
                        >
                            {t('MainApp.BottomNav.qrTab')}
                        </button>
                        <button
                            style={activeTab === 'barcode' ? styles.activeTab : styles.tab}
                            onClick={() => handleTabClick('barcode')}
                        >
                            {t('MainApp.BottomNav.barcodeTab')}
                        </button>
                    </div>

                    {/* Fixed Content Area */}
                    <div style={styles.contentArea}>
                        {activeTab === 'qr' ? (
                            <QRCodeCanvas value={user.id} style={styles.qrCode} />
                        ) : (
                            <canvas ref={canvasRef => renderBarcode(canvasRef, user.id)} style={styles.barcode} />
                        )}
                    </div>
                </div>
            </Modal>
        </div>
    );
};

export default BottomNav;

// CSS-in-JS styles for the component
const styles = {
    bottomNav: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        backgroundColor: '#fff',
        padding: '10px 0',
        position: 'fixed',
        bottom: 0,
        maxHeight: '10vh',
        height: '10vh',
    },
    navItem: {
        textAlign: 'center',
        flex: 1,
    },
    cardNavItem: {
        textAlign: 'center',
        flex: 1,
        fontSize: '1.2rem',
    },
    navIcon: {
        fontSize: '1.5rem',
        marginBottom: '0.3rem',
    },
    navText: {
        fontSize: '0.9rem',
        fontWeight: 'bold',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    modalContent: {
        position: 'relative',
        width: '80vw',
        textAlign: 'center',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'none',
        border: 'none',
        fontSize: '1.2rem',
        cursor: 'pointer',
    },
    tabsContainer: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '10px',
        marginBottom: '20px',
        gap: '1rem',
    },
    tab: {
        padding: '0.5rem 1rem',
        border: '1px solid #ccc',
        backgroundColor: '#f5f5f5',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    activeTab: {
        padding: '0.5rem 1rem',
        border: '1px solid #6200ee',
        backgroundColor: '#6200ee',
        color: '#fff',
        borderRadius: '4px',
        cursor: 'pointer',
        fontSize: '1rem',
    },
    contentArea: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '300px', // Fixed height to prevent resizing
        overflow: 'hidden',
    },
    qrCode: {
        width: '40vw',
        height: '40vw',
    },
    barcode: {
        width: '80vw',
        height: 'auto',
    },
};

// Modal styles
const modalStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
        width: '80vw',
        maxHeight: '80vh',
        overflow: 'auto',
        borderRadius: '8px',
        padding: '20px',
        backgroundColor: '#fff',
    },
};
