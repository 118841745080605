import React, { createContext, useState, useEffect } from 'react';
import SiteSettings from '../models/SiteSettings';
import { fetchSiteSettings } from '../services/Api';

// Create the SettingsContext
export const SettingsContext = createContext(undefined);

export const SettingsProvider = ({ children }) => {
    const [siteSettings, setSiteSettings] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isFetched, setIsFetched] = useState(false); // Add a flag to track if data is fetched

    useEffect(() => {
        let isMounted = true;

        const loadSettings = async () => {
            if (isFetched) return;

            try {
                const settingsData = await fetchSiteSettings();
                const { countries, languages } = settingsData;
                const settings = new SiteSettings({ countries, languages });

                if (isMounted) {
                    setSiteSettings(settings);
                    setIsFetched(true); // Set the flag to true
                    setLoading(false);
                }
            } catch (error) {
                if (isMounted) {
                    console.error('Failed to fetch site settings', error);
                    setError('Failed to load settings');
                    setLoading(false);
                }
            }
        };

        loadSettings();

        return () => {
            isMounted = false;
        };
    }, [isFetched]);

    return (
        <SettingsContext.Provider value={{ siteSettings, loading, error }}>
            {children}
        </SettingsContext.Provider>
    );
};


