import React, { useEffect, useState } from 'react';
import { GetDiscountsPreview } from '../services/Api';

const DiscountsPreview = () => {
    const [showingDiscounts, setShowingDiscounts] = useState([]);
    const [currentDiscount, setCurrentDiscount] = useState(null);
    const colors = ['#f4a261', '#2a9d8f', '#e76f51', '#01b2fc', '#e9c46a'];

    useEffect(() => {
        // Fetch initial discounts when the component mounts
        fetchDiscounts();

        // Start the interval for displaying discounts
        const interval = setInterval(() => {
            shiftDiscount();
        }, 3000); // Change discount every 3 seconds

        return () => clearInterval(interval); // Cleanup interval on component unmount
    }, []);

    useEffect(() => {
        // Fetch more discounts when there is only one left in the array
        if (showingDiscounts.length === 1) {
            fetchDiscounts();
        }
    }, [showingDiscounts]);

    const fetchDiscounts = async () => {
        try {
            const newDiscounts = await GetDiscountsPreview();
            if (newDiscounts.length > 0) {
                setShowingDiscounts((prev) => {
                    const combined = [...prev, ...newDiscounts];
                    if (!currentDiscount) {
                        setCurrentDiscount(combined[0]); // Set the first discount as the current if none is set
                    }
                    return combined;
                });
            }
        } catch (error) {
            console.error('Error fetching discounts:', error);
        }
    };

    const shiftDiscount = () => {
        setShowingDiscounts((prevDiscounts) => {
            if (prevDiscounts.length > 0) {
                const updatedDiscounts = [...prevDiscounts];
                const nextDiscount = updatedDiscounts.shift(); // Remove the first discount
                setCurrentDiscount(nextDiscount); // Set the next discount as the current one
                return updatedDiscounts;
            }
            return prevDiscounts;
        });
    };

    if (!currentDiscount) {
        return <div>Loading discounts...</div>;
    }

    return (
        <div style={{ ...styles.discountCard, backgroundColor: getRandomColor() }}>
            <img src={currentDiscount.manufacturer_item.image_url} alt={currentDiscount.manufacturer_item.product_name} style={styles.productImage} />
            <div style={styles.detailsContainer}>
                <p style={styles.holderName}>{currentDiscount.current_holder?.name}</p>
                <h3 style={styles.productName}>{currentDiscount.manufacturer_item.product_name}</h3>
            </div>
            <div style={styles.discountCircle}>
                {currentDiscount.discount}%
            </div>
        </div>
    );
};

export default DiscountsPreview;

// CSS-in-JS styles
const styles = {
    discountCard: {
        display: 'flex',
        alignItems: 'center',
        height: '20vh',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '1rem',
        textAlign: 'left',
        position: 'relative',
        transition: 'background-color 0.5s ease',
        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        color: '#fff',
    },
    productImage: {
        width: '20vh',
        height: '20vh',
        borderRadius: '8px',
        objectFit: 'fit',
        marginRight: '1rem',
    },
    detailsContainer: {
        flex: 1,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        paddingRight: '1rem',
    },
    holderName: {
        fontSize: '1rem',
        fontWeight: 'bold',
        color: '#fff',
        marginBottom: '0.5rem',
    },
    productName: {
        fontSize: '1.2rem',
        fontWeight: 'bold',
        color: '#fff',
    },
    discountCircle: {
        position: 'absolute',
        bottom: '0',
        right: '0',
        width: '7vh',
        height: '7vh',
        borderRadius: '50%',
        backgroundColor: '#e76f51', // Color for the discount circle
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        fontSize: '1.5rem',
        fontWeight: 'bold',
        color: '#fff',
    }
};

// Helper function to get a random background color
const getRandomColor = () => {
    const colors = ['#18de15', '#ee0f0f', '#e808e5', '#0a45c7', '#e9c46a'];
    return colors[Math.floor(Math.random() * colors.length)];
};
