import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AuthLayout from '../layouts/AuthLayout';
import MainButton from '../components/elements/Buttons/MainButton';
import MainTextInput from '../components/elements/Inputs/MainTextInput';
import ContainerTitle from '../components/elements/Labels/ContainerTitle';

const ForgotPasswordPage = () => {
    const [email, setEmail] = useState('');
    const [isInvalid, setIsInvalid] = useState(false);
    const navigate = useNavigate();
    const { t } = useTranslation();

    const handleSendEmail = () => {
        if (email === '') {
            setIsInvalid(true);
        } else {
            setIsInvalid(false);
            console.log('Forgot Password Email:', email);
            // Implement Forgot Password API call here
        }
    };

    return (
        <AuthLayout>
            <div style={styles.container}>
                <div style={styles.centeredBackButton}>
                    <button onClick={() => navigate(-1)} style={styles.backButton}>
                        {t('forgotPassword.backButton')}
                    </button>
                </div>

                <ContainerTitle title={t('forgotPassword.title')} />

                <MainTextInput
                    placeholder={t('forgotPassword.emailPlaceholder')}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    isValid={!isInvalid}
                />
                {isInvalid && <p style={styles.validation}>{t('forgotPassword.emailRequired')}</p>}

                <MainButton onClick={handleSendEmail}>{t('forgotPassword.sendButton')}</MainButton>
            </div>
        </AuthLayout>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        height: '100%',
    },
    centeredBackButton: {
        marginBottom: '20px',
        textAlign: 'center',
    },
    backButton: {
        backgroundColor: 'transparent',
        color: '#6200ee',
        border: 'none',
        fontSize: '16px',
        cursor: 'pointer',
    },
    validation: {
        color: 'red',
        fontSize: '0.9rem',
        marginTop: '-10px',
        marginBottom: '10px',
    },
};

export default ForgotPasswordPage;
